.question_box {
	width: 100%;
	height: 100%;
	display: flex !important;
	flex-direction: column;
	align-items: center;
    .question_box_finished_img {
        width: 516.1px;
		height: auto;
		margin-top: 157px;
    }
    .question_box_finished_title {
        margin-top: 80px;
        color: #EE5050;
        font-size: 42px;
        font-weight: 500;
    }
    .question_box_finished_content {
        font-size: 30px;
        color: #9A9A9A;
        margin-top: 8px;
    }
	.question_box_title {
		width: 95%;
		color: #222222;
		font-size: 40px;
		margin-top: 93px;
	}

	.question_box_content {
		width: 95%;
		color: #707070;
		font-size: 32px;
		margin-top: 93px;
		margin-top: 25px;
	}

	img {
		width: 516.1px;
		height: 483.97px;
		margin-top: 100px;
	}

	.question_box_button {
        margin-top: 150px;
		width: 443px;
		height: 75px;
		background: #EF5050 !important;
		opacity: 1;
        border-radius: 38px;
        color: #FFFFFF;
        font-size: 30px;
	}
}
