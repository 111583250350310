.write {
	min-height: 100%;
	padding-bottom: 80px;
}

.write,
.self__introduction,
.ideal__object,
.core__data {
	background-color: white;

	.MuiPaper-elevation4 {
		box-shadow: none;
	}

	.app__header {
		justify-content: space-between;
		padding: 0 20px 0 22px;
		height: 52px;

		.title {
			font-size: 37px;
			color: #222;
			line-height: 52px;
			flex: 1;
			text-align: center;
		}

		.MuiSvgIcon-root {
			height: 40px;
			color: #2B2B2B;
			width: 37px;
			display: block;
			margin-top: -4px;
			margin-right: 40px;
		}

		.next__btn {
			font-size: 32px;
			font-weight: 500;
			color: #E63737;
			line-height: 45px;
		}

		.MuiButton-text {
			padding: 0;
		}

		.MuiButton-root {
			min-width: 0;
		}
	}

	.tips {
		margin-top: 110px;
		height: 106px;
		background: #FFE8B5;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		line-height: 42px;
		color: #B47C21;
		padding: 0 20px;
	}

	.am-list-item {
		padding: 0 20px;

		.am-list-line {
			padding-right: 0;
			font-size: 32px;
			color: #484747;

			.am-list-extra {
				color: #484747;
			}

			.am-list-content,
			.am-input-label {
				color: #484747;
				font-weight: 500;
				font-size: 32px;
			}

			.am-input-label-5 {
				width: 200px;
			}
		}

		.am-input-control input {
			text-align: right;
			font-size: 32px;

			&::placeholder {
				color: #B4B4B4;
			}
		}
	}

	.am-switch,
	.checkbox {
		width: 120px;
		height: 56px;
		border-radius: 28px;
		background-color: rgb(239, 80, 80);
		color: #FFF;
	}

	.am-switch input[type="checkbox"]:checked+.checkbox:after {
		transform: translateX(66px);
	}

	.am-switch input[type="checkbox"]:checked+.checkbox:before {
		content: '男';
		padding-left: 20px;
		width: 30px;
		color: #FFF;
		z-index: 2;
		transform: none;
	}

	.am-switch .checkbox:before {
		width: 120px;
		height: 56px;
		top: 5px;
		left: 0;
		background-color: transparent;
		font-size: 30px;
		content: '女';
		padding-right: 20px;
	}

	.checkbox::after {
		width: 48px;
		height: 48px;
	}
}

.self__introduction {
	.img {
		font-size: 30px;
		color: #484747;
		font-weight: 500;
	}

	.am-wingblank.am-wingblank-lg {
		margin-left: 20px;
	}

	.am-image-picker-list {
		padding-left: 0;
	}

	.am-list:last-child {
		padding-bottom: 30px;
	}
}

.core__data .am-list-header,
.self__introduction .am-list-header,
.ideal__object .am-list-header,
.privacy__info .am-list-header {
	font-size: 32px;
	color: #484747;
	font-weight: 500;
	padding-left: 20px;
}

.core__data .am-list .am-list-body,
.self__introduction .am-list .am-list-body,
.ideal__object .am-list .am-list-body,
.question .am-list .am-list-body,
.privacy__info .am-list .am-list-body {
	font-size: 32px;

	&::before,
	&::after {
		width: 0;
	}

	.am-textarea-item {
		padding: 0 20px;

		.am-textarea-control {
			padding: 0;
			width: 710px;
			min-height: 87px;
			background: #FFFFFF;
			border: 1px solid #D5D5D5;
			border-radius: 4px;
			font-size: 32px;

			textarea {
				margin: 20px;
				padding-right: 40px;
				font-size: 32px;
				color: #464646;
			}
		}
	}
}

.ideal__object .am-list:first-child {
	.am-list-line::after {
		content: '';
		position: absolute;
		background-color: #ddd;
		display: block;
		z-index: 1;
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1PX;
		transform-origin: 50% 100%;
		transform: scaleY(0.5);
	}
}

.am-picker-popup-item {
	color: rgb(239, 80, 80);
}

.core-data .am-list-item {
	border-top: none;
}

.core-data .am-list .am-list-body::before {
	width: 0;
}

.QQ .am-list-line {
	border-bottom: 1px solid #D5D5D5;
}

.question .am-textarea-control textarea {
	height: 174px;
}

.modal__question {
	min-height: 100px;
	max-height: 800px;
}

.am-modal-mask {
	z-index: 1100;
}

.am-modal-wrap {
	z-index: 1101;

	.am-modal-title {
		font-size: 35px;
		font-weight: bold;
	}

	.modal__question .am-list-body {
		&::after {
			width: 0;
		}

		.am-list-item {
			width: 100%;
		}
	}

	.question__checked {
		display: flex;
		align-items: center;
	}

	.my-radio {
		width: 28px;
		height: 28px;
		border: 2px solid #E63737;
		border-radius: 3px;

		.am-radio-checked {
			background: #E63737;
			vertical-align: top;
			width: 28px;
			height: 28px;
			background-color: #E63737;
			margin-top: -2px;
			margin-left: -1px;
			border-radius: 3px;
		}

		.am-radio.am-radio-checked .am-radio-inner::after {
			border-color: #fff;
			top: 3px;
			width: 10px;
			height: 15px;
			right: 10px;
		}
	}
}

.only_box .am-list-line::after {
	background: none !important;
}

.only_box .only_box_item .am-list-line {
	border-bottom: 1px solid #EF5050;
}

.ideal_object_box .am-list-line {
	border: 1px solid #D5D5D5;
}

.ideal_object_box input {
	padding: 0.625rem !important;
	text-align: left !important;
}

.private_box_house {
	margin-top: 12px;
}

.private_box_house .am-list-line {
	border-bottom: 1px solid #D5D5D5;
}

.com_qrcode_box {
  display: flex !important;
  flex-flow: column !important;
  align-items: center !important;
  margin-bottom: 40px;
  img {
    width: 460px !important;
  }
  div {
    margin-top: 20px;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    color: #222222;
  }
}

.bg {
  top: -100px !important;
  left: -10px !important;
}