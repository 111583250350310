.ToastComponent {
  z-index:99999999!important;
  .MuiSnackbar-anchorOriginBottomCenter {
	display: contents;
	.shadow{
	  width: 100%;
	  height: 100%;
	  background: none;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  }
  .MuiPaper-root.MuiAlert-root {
	display: inline-flex;
	color:#fff!important;
	background-color: rgba(0, 0, 0, 0.7)!important;
	border-radius: 12px !important;
	padding-top:0;
	padding-bottom: 0;
  }
  .loading{
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	min-width: 280px;
	.MuiCircularProgress-colorPrimary{
	  color:#fff;
	}
	.MuiAlert-icon{
	  margin: 0;
	}
  }
  .toast{
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	.MuiAlert-icon{
	  margin: 0;
	  color:#fff;
	  font-size: 100px;
	  padding-bottom: 0;
	}
  }
}
