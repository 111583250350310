.home-wrap {
	display: flex;
	flex-flow: column;
	flex: 1;
	width: 100%;
	overflow-y: scroll;

	.empty_box {
		position: absolute;
		top: calc((100% - 400px) / 2);
		width: 100%;
		height: 500px;
		text-align: center;
		color: #bfbfbf;

		img {
			width: 411px;
			height: 264px;
		}
	}

	.banner {
		width: 100%;
		display: flex;
		align-items: center;
		background-color: #fff;
		height: 258px !important;
		padding: 12px 0 0 20px;

		.slider-list {
			// text-align: center;
			padding-right: 20px !important;
		}

		.banner__img {
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 100%;
			margin: 0 auto;
			height: 244px !important;
			background-size: cover;
			background-position: center;
		}
	}

	.tabs-box {
		display: flex;
		align-items: center;
		background-color: #fff;

		//box-shadow: 0 1px 2px 0 rgba(172, 172, 172, 0.1), 0 2px 5px 0 rgba(172, 172, 172,0.08), 0 1px 10px 0 rgba(172, 172, 172,0.06);
		//transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		.tab {
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			flex: 1;
			height: 96px;
			font-size: 34px;
			color: #747474;
			position: relative;
			border-radius: 0;

			i {
				width: 55px;
				height: 4px;
				background: #2e2e2e;
				border-radius: 10px;
				display: none;
				position: absolute;
				bottom: 20px;
			}
		}

		.tab.active {
			color: #2e2e2e;

			.MuiButton-label {
				display: flex;
				flex-flow: column;
				align-items: center;

				i {
					display: inline;
				}
			}
		}
	}

	.loader {
		text-align: center;
		padding: 20px 0;
	}

	.home {
		height: calc(100% - 352px);
		overflow-y: scroll;
		padding-top: 87px;

		.infinite-scroll {
			height: 100%;

			.list-item {
				padding-top: 30px;
			}

			.user {
				width: 170px;
				height: 348px;
				display: inline-flex;
				flex-flow: column;
				align-items: center;

				.header-img {
					width: 156px;
					height: 156px;
					border-radius: 100%;
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
					margin-bottom: 20px;
					object-fit: cover;
				}

				.name_gender {
					margin-bottom: 10px;
					display: flex;

					.username {
						font-size: 30px;
						font-weight: bold;
						line-height: 42px;
						color: #2e2e2e;
						max-width: 146px;
					}

					.gender {
						width: 32px;
						height: 32px;
						margin-left: 4px;
					}
				}

				.age_city {
					font-size: 28px;
					font-weight: 400;
					line-height: 30px;
					color: #9f9f9f;
					display: flex;
					padding-bottom: 6px;

					.age {
						margin-right: 6px;
					}

					.city {
						text-align: right;
						padding-left: 6px;
						height: 30px;
						border-left: 2px solid #9f9f9f;
						max-width: 120px;
					}
				}

				.school {
					font-size: 28px;
					font-weight: 400;
					line-height: 40px;
					color: #9f9f9f;
					text-align: center;
					width: 100%;
				}

				.ellipsis-line {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}

	.footer__card {
		bottom: 56px;
		width: 100%;
		height: 205px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.headers {
			display: flex;

			.img_box {
				width: 90px;
				height: 90px;
				border-radius: 50%;
				overflow: hidden;
				margin-right: 10px;
				;

				img {
					width: 90px;
					height: 90px;
					border-radius: 50%;
					object-fit: cover;
					filter: blur(4px);
				}
			}

			img {
				width: 90px;
				height: 90px;
				object-fit: cover;
				border-radius: 50%;
				overflow: hidden;
			}
		}

		.view {
			font-size: 25px;
			color: #575757;
			margin-top: 10px;
			display: flex;
			align-items: center;

			span {
				margin-right: 13px;
			}

			p.arrow {
				display: inline-block;
				width: 0;
				height: 0;
				border-top: 10px solid transparent;
				border-left: 18px solid #575757;
				border-right: 18px solid transparent;
				border-bottom: 10px solid transparent;
			}
		}
	}
}
