.detail {
	height: 100%;
	display: flex !important;
	flex-flow: column;
	justify-content: space-between;
	// overflow: hidden;
	.join_box_recommend {
		text-align: center;
		.join_recommend {
			height: 73px;
			width: 44px;
		}
	}
	.loading {
		z-index: 99999999;
		.loading-box {
			display: flex;
			flex-flow: column;
			align-items: center;
			.MuiCircularProgress-colorPrimary {
				color: #e3e3e3 !important;
			}
			.loading-text {
				margin-top: 30px;
				color: #e3e3e3;
			}
		}
	}
	.share-box {
		z-index: 99999999;
		.share-img {
			width: 439px;
			height: 243px;
			position: absolute;
			top: 0;
			right: 30px;
		}
	}
	.toast {
		z-index: 99999999;
		.MuiSnackbar-anchorOriginBottomCenter {
			bottom: 8px;
			height: 100%;
		}
	}
	.ellipsis-line {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.wrap {
		flex: 1;
		// overflow-y: scroll;
		.top__ad {
			height: 84px;
			background: #E84D4D;
			margin: 0 20px;
			border-radius: 13px;
			display: flex;
			align-items: center;
			z-index: 2;
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			.close {
				margin-left: 15px;
				width: 32px;
				height: 32px;
			}
			.head {
				margin-left: 15px;
				display: flex;
				align-items: center;
				flex: 1;
			}
			.head__img {
				width: 55px;
				height: 55px;
				padding: 1px;
				border-radius: 50%;
				background-color: #fff;
				margin-right: -15px;
				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}
			.more {
				display: flex;
				justify-content: center;
				padding-left: 10px;
				color: #BCBCBC;
			}
			.right {
				margin-right: 25px;
				color: #fff;
				display: flex;
				text-align: right;
				align-items: center;
				&::after {
					content: ' ';
					display: inline-block;
					width: 14px;
					height: 14px;
					border-top: 2px solid #fff;
					border-right: 2px solid #fff;
					transform: rotate(45deg);
				}
			}
		}
		.top {
			width: 100%;
			padding: 0 20px;
			display: flex;
			flex-direction: column;
			.big-img {
				height: 544px;
				object-fit: cover;
				width: 100%;
				margin-top: -8px;
			}
		}

		.container {
			padding: 20px;
			overflow-x: hidden;
			.basic-info {
				padding: 0 24px;
				width: calc(100% - 10px);
				border: 4px solid #212121;
				border-radius: 10px;
				position: relative;
				background: #fff;
				margin-top: 60px;

				.tag-box {
					height: 77px;
					display: flex;
					justify-content: space-between;
					margin-top: -60px;

					.tag-left {
						width: 83px;
						height: 77px;
					}

					.tag-right {
						width: 71px;
						height: 77px;
					}
				}

				.name-gender {
					display: flex;
					align-items: center;
					margin-left: 17px;
					margin-top: 60px;

					.name {
						font-size: 34px;
						font-weight: bold;
						line-height: 48px;
						color: #000000;
						margin-right: 20px;
					}

					.gender {
						width: 32px;
						height: 32px;
						border-radius: 50%;
					}
				}

				.info {
					margin: 30px 0 50px 16px;
					font-size: 30px;
					font-weight: 400;
					line-height: 30px;
					color: #000;
					.info-item {
						display: flex;
						align-items: center;
						align-items: flex-start;
						font-weight: 400;
						padding: 10px 26px;
						.info-item-value, .info-item-value-7 {
							word-break: break-word;
						}
					}
				}
			}

			.other-info {
				padding: 0 24px;
				width: calc(100% - 10px);
				border: 4px solid #212121;
				border-radius: 10px;
				position: relative;
				background: #fff;
				margin-top: 100px;
				margin-bottom: 30px;

				.tag-box {
					height: 77px;
					display: flex;
					justify-content: space-between;
					margin-top: -60px;
					position: relative;

					.join-box {
						position: absolute;
						width: 100%;
						display: flex;
						justify-content: center;
						margin-top: -50px;

						.join {
							height: 73px;
							width: 44px;
						}
					}

					.tag-left {
						width: 83px;
						height: 77px;
					}
				}

				.education {
					padding: 30px 0;

					.info {
						padding: 40px 0 30px 17px;
						line-height: 56px;
						font-size: 30px;
					}

					.education-img {
						width: 100%;
						margin-bottom: 40px;
					}
				}

				.introduce {
					margin-bottom: 40px;

					.info-title {
						margin-bottom: 40px;
					}

					.introduce-info {
						display: flex;
						align-items: flex-start;

						.dot {
							width: 16px;
							height: 16px;
							background: #ef5050;
							border-radius: 50%;
							margin-top: 16px;
							margin-right: 14px;
						}

						.info {
							flex: 1;
							font-size: 30px;
							font-weight: 400;
							line-height: 54px;
							color: #000000;
						}
					}

					.introduce-img {
						width: 100%;
						margin-bottom: 30px;
						margin-top: 28px;
					}
				}

				.friends {
					margin-bottom: 40px;

					.friends-item {
						border-bottom: 1px dashed #e3e3e3;
						display: flex;
						margin-top: 45px;
						padding-bottom: 33px;

						.friends-icon {
							width: 60px;
							height: 50px;
							margin-right: 20px;
							position: relative;
						}

						.item-info {
							flex: 1;

							div {
								font-size: 36px;
								font-weight: bold;
								line-height: 50px;
								color: #000000;
							}

							p {
								font-size: 30px;
								font-weight: 400;
								line-height: 54px;
								color: #606060;
							}
						}
					}

					.friends-info .friends-item:nth-last-of-type(1) {
						border: 0;
					}

					.friends-img {
						width: 100%;
						margin-bottom: 30px;
						margin-top: 28px;
					}
				}
				.ideal-lover {
					padding: 30px 0;
					padding-top: 0;
					.info {
						padding: 40px 0 30px 17px;
						line-height: 56px;
					}

					.ideal-lover-img {
						width: 100%;
						margin-bottom: 40px;
					}
				}
				.question {
					padding: 30px 0;
					padding-top: 0;
					.question-list {
						.question-item {
							padding: 0;
							display: flex;
							flex-flow: column;
							align-items: flex-start;
							border-bottom: 1px dashed #e3e3e3;
							.question-detail {
								display: flex;
								margin: 20px 0;
								.question-index {
									font-size: 42px;
									font-weight: 400;
									line-height: 59px;
									color: #000000;
									margin-right: 20px;
								}
								.question-title {
									font-size: 32px;
									font-weight: bold;
									line-height: 45px;
									color: #000000;
									margin-top: 6px;
									word-break: break-word;
								}
								.answer {
									font-size: 30px;
									font-weight: 400;
									line-height: 60px;
									color: #414141;
									word-break: break-word;
								}
							}
							.title.question-detail {
								margin-bottom: 0;
							}
						}
						.question-item:nth-last-of-type(1) {
							border: 0;
						}
					}
				}
				.privacy {
					padding: 30px 0;
					position: relative;
					display: flex;
					flex-flow: column;
					justify-content: center;
					.privacy-title {
						position: relative;
					}
					.privacy-list {
						margin-top: 30px;
						position: relative;
						.privacy-item {
							padding-top: 5px;
							padding-bottom: 5px;
							display: block;
							word-break: keep-all;
							.privacy-title {
								margin-right: 20px;
								font-size: 30px;
								font-weight: 400;
								line-height: 56px;
								color: #6f6f6f;
							}
							.privacy-detail {
								filter: blur(8px);
								font-size: 30px;
								font-weight: 400;
								line-height: 56px;
								color: #222;
								word-break: break-word;
								display: inline;
							}
							.privacy-info {
								font-size: 30px;
								font-weight: 400;
								line-height: 56px;
								color: #222;
								word-break: break-word;
								display: inline;
							}
						}
					}
					.lock-btn {
						position: absolute;
						width: 358px;
						height: 81px;
						background: #ef5050;
						box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
						border-radius: 60px 0px 0px 60px;
						right: -60px;
						font-size: 30px;
						font-weight: 400;
						line-height: 56px;
						color: #ffffff;
						display: flex;
						align-items: center;
						padding-left: 36px;
						img {
							height: 49px;
							width: 42px;
							margin-right: 12px;
						}
					}
					.lock-btn::after {
						content: "";
						position: absolute;
						border-top: 10px solid #ac0909;
						border-right: 11px solid transparent;
						border-bottom: 10px solid transparent;
						border-left: 11px solid #ac0909;
						bottom: -20px;
						right: 0;
					}
				}
			}

			.basic-info:after,
			.other-info:after {
				content: "";
				width: 100%;
				border: 4px solid #212121;
				border-radius: 10px;
				margin: auto -6px -6px auto;
				position: absolute;
				top: 10px;
				bottom: -10px;
				right: -8px;
				left: 8px;
				z-index: -1;
			}
		}
		.container {
			position: relative;
			.tag-box-swiper {
				height: 77px;
				display: flex;
				justify-content: space-between;
				width: 100%;
				position: absolute;
				top: -7px;
				.tag-left {
					width: 83px;
					height: 77px;
				}

				.tag-right {
					width: 71px;
					height: 77px;
				}
			}
		}
	}

	.last {
		margin-bottom: 142px;
	}
	.footer {
		display: flex;
		height: 112px;
		min-height: 112px;
		background: #fff;
		padding: 0 20px;
		align-items: center;
		box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.06);
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 2;
		.MuiGrid-spacing-xs-3 > .MuiGrid-item {
			padding: 12px;
		}
		.footer-left {
			display: flex;
			padding-left: 0;
			.btns {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: center;
				font-size: 26px;
				font-weight: 400;
				line-height: 37px;
				color: #4a4a4a;
				padding: 0 20px;
				.MuiButton-label {
					display: flex;
					flex-flow: column;
				}
			}

			.home-btn {
				img {
					width: 42px;
					height: 40px;
				}
			}

			.share-btn {
				padding-left: 0;
				img {
					width: 46px;
					height: 40px;
				}
			}
		}
		.footer-right {
			display: flex;
			.btn {
				flex: 1;
				height: 75px;
				//line-height: 75px;
				background: #ef5050;
				text-align: center;
				padding: 0;
				border-radius: 38px;
				font-size: 30px;
				font-weight: 400;
				color: #ffffff;
				text-transform: lowercase;
				margin-left: 16px;
				.btn-name {
					max-width: 180px;
				}
			}
			.matchmaker {
				background: #f09400;
				margin-left: 0;
			}
		}
	}

	.info-title {
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		color: #000000;
		text-align: center;
		position: relative;
		display: flex;
		justify-content: center;
		z-index: 1;
		span {
			position: relative;
			z-index: 1;
		}
	}

	.info-title:after {
		content: "";
		position: absolute;
		height: 13px;
		width: 173px;
		background: #ef5050;
		border-radius: 50%;
		bottom: 0;
		z-index: 0;
	}
}
.paylist-dialog {
	height: calc(100% - 112px - 112px);
	overflow: hidden;
	margin-top: 112px;
	.MuiBackdrop-root {
		height: calc(100% - 112px - 112px);
		margin-top: 112px;
		background: none;
		position: relative;
	}
	.MuiDialog-container {
		align-items: flex-end;
		.MuiDialog-paper {
			margin: 0;
			width: 100%;
			max-width: 100%;
			border-radius: 20px 20px 0 0;
			border-bottom: 1px solid #e3e3e3;
			margin-bottom: 0;
			.MuiDialogContent-root {
				padding: 36px 20px 0 20px;
				.paylist-item {
					width: 100%;
					background: #ffffff;
					border: 1px solid #cecece;
					border-radius: 12px;
					margin-bottom: 22px;
					padding: 24px;
					.paylist-top {
						font-size: 32px;
						font-weight: 400;
						line-height: 38px;
						color: #4a4a4a;
						.text {
							font-size: 27px;
						}
					}
					.paylist-bottom {
						display: flex;
						justify-content: space-between;
						position: relative;
						.paylist-b-left {
							font-size: 57px;
							font-weight: 400;
							line-height: 42px;
							color: #e84d4d;
							padding-top: 20px;
							.tag {
								font-size: 30px;
							}
						}
						.paylist-b-reduce {
							position: absolute;
							left: 145px;
							margin-top: 20px;
							width: 162px;
							height: 50px;
							text-align: center;
							line-height: 50px;
							font-size: 26px;
							font-weight: 400;
							color: #ff8f33;
							background-color: #ffe7c7;
							padding: 0 10px;
							border-radius: 28px;
						}
						.paylist-pay {
							width: 162px;
							height: 58px;
							background: #e84d4d;
							border-radius: 8px;
							font-size: 28px;
							font-weight: 400;
							line-height: 26px;
							color: #ffffff;
							margin-top: 20px;
						}
					}
				}
				.paylist-item.active {
					background: #fffaf9;
					border: 1px solid #fc6e6e;
				}
			}
		}
	}
}
.matchmaker-box-detail {
	z-index: 10000;
	position: absolute;
	.MuiDialog-container {
		align-items: center;
		.MuiDialog-paperScrollPaper {
			display: flex;
			max-height: 100%;
			flex-direction: column;
		}
		.MuiDialog-paper {
			margin: 0 !important;
			position: absolute;
			min-width: 580px;
			min-height: 680px;
			border-radius: 25px;
			overflow: visible;
			.MuiDialogContent-root {
				// width: 100%;
				background: #fff;
				display: flex;
				flex-flow: column;
				border-radius: 25px;
				padding: 0 !important;
				position: relative;
				overflow: visible;
				.match_image {
					width: 202px;
					margin: 0 auto;
					margin-top: 80px;
				}
				.close-matchmaker {
					position: absolute;
					font-size: 30px;
					top: 0px;
					right: 0px;
					width: 70px;
					height: 70px;
					background: #fff;
					border-radius: 100%;
				}
				.info {
					text-indent: 40px;
					font-size: 30px;
					font-weight: 400;
					line-height: 45px;
					width: 90%;
					color: #000000;
					margin: 0 auto;
					margin-top: 40px;
					max-height: 200px;
					overflow-y: auto;
					user-select: none;
				}
				.qrcode-box {
					display: flex;
					flex-flow: column;
					align-items: center;
					margin-bottom: 40px;
					padding: 20px;
					div {
						font-size: 30px;
						font-weight: 400;
						line-height: 40px;
						color: #7c7c7c;
					}
				}
				.matchmaker-btn {
					margin-top: 60px;
					width: 472px;
					height: 75px;
					background-color: #ef5050;
					color: #ffffff;
					opacity: 1;
					border-radius: 38px;
					left: calc(50% - 236px);
					padding-left: 0;
					padding-right: 0;
				}
			}
			.dialog-content {
				width: 580px;
			}
		}
	}
	.dialog-content__matchmaker {
		width: 614px;
		.bg {
			width: 100%;
			height: 277px;
		}
		.content {
			font-size: 32px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			h1 {
				margin-top: 44px;
				height: 49px;
				font-size: 35px;
				font-weight: 500;
				position: relative;
				color: #000;
				z-index: 2;
				&::after {
					content: ' ';
					position: absolute;
					bottom: 6px;
					z-index: -1;
					left: 50%;
					margin-left: -142px;
					width: 284px;
					height: 13px;
					background: #EF5050;
					border-radius: 50%;
				}
			}
			p {
				margin-top: 24px;
				padding: 0 30px;
				line-height: 45px;
			}
		}
		.see__matchmaker {
			margin-bottom: 72px;
		}
	}

}
